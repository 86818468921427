import React, { useState } from "react";
import ErrorHandler from "./ErrorHandler";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import RailsTimeZone from "rails-timezone";

import {
  AdminApiClient,
  AdminApiContext,
  FormEditorV3,
  gregAdminTheme,
} from "@getregistered/greg-editors";

import railsFetch from "./railsFetch";
import { fieldsFromRails, fieldsToRails } from "./registrationFieldMapping";

export default function RegistrationFormEditor({
  pages,
  shoppingCartEnabled,
  shoppingCartCurrency = "$",
  sessionBookingEnabled = false,
  sessionSlotDescriptionsEnabled = false,
  tracksEnabled = false,
  tracksBlockedByUntrackedRegistrations = false,
  roomingEnabled = false,
  guestBookingEnabled = false,
  documentUploadsEnabled = false,
  metadataEnabled = false,
  leadScannersEnabled = false,
  occupancyPricingEnabled = false,
  protectAttendeeReferenceFields = true,
  timezone,
  startDate,
  endDate,
  apiBaseUrl,
  saveUrl,
  previewsUrl,
}) {
  const [fields, setFields] = useState(fieldsFromRails(pages));
  const apiClient = new AdminApiClient(apiBaseUrl, railsFetch);

  const handleSave = async (newFields) => {
    const mappedFields = fieldsToRails(newFields);
    const response = await railsFetch(saveUrl, {
      method: "PUT",
      body: JSON.stringify({ form: { fields: mappedFields } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const savedFields = await response.json();
    setFields(fieldsFromRails(savedFields.pages));
  };

  const handlePreview = async (newFields) => {
    const mappedFields = fieldsToRails(newFields);
    const response = await railsFetch(previewsUrl, {
      method: "POST",
      body: JSON.stringify({ form_preview: { fields: mappedFields } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const currentPreviewUrl = response.headers.get("Location");
    window.open(currentPreviewUrl, "greg_form_preview");
  };

  return (
    <ErrorHandler>
      <ChakraProvider theme={gregAdminTheme}>
        <AdminApiContext.Provider value={apiClient}>
          <FormEditorV3
            initialFields={fields}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            shoppingCartEnabled={shoppingCartEnabled}
            shoppingCartCurrency={shoppingCartCurrency}
            sessionBookingEnabled={sessionBookingEnabled}
            sessionSlotDescriptionsEnabled={sessionSlotDescriptionsEnabled}
            tracksEnabled={tracksEnabled}
            tracksBlockedByUntrackedRegistrations={
              tracksBlockedByUntrackedRegistrations
            }
            roomingEnabled={roomingEnabled}
            guestBookingEnabled={guestBookingEnabled}
            documentUploadsEnabled={documentUploadsEnabled}
            metadataEnabled={metadataEnabled}
            leadScannersEnabled={leadScannersEnabled}
            occupancyPricingEnabled={occupancyPricingEnabled}
            protectAttendeeReferenceFields={protectAttendeeReferenceFields}
            multipleSessionSlotChoices
            timezone={
              timezone !== undefined ? RailsTimeZone.from(timezone) : undefined
            }
            onSave={handleSave}
            onPreview={handlePreview}
          />
        </AdminApiContext.Provider>
      </ChakraProvider>
    </ErrorHandler>
  );
}
